import React, { useCallback, useState } from 'react';
import { Button, DatePicker, Form, Select, Space, Typography } from 'antd';
import { PatientDTO, VisitDTO } from '~/dtos';
import { DownloadOutlined } from '@ant-design/icons';
import {
  useConfirmVisitExit,
  useGenerateExcerptDocument,
} from '~/queries/useVisit';
import { createPatientVisitRoute, ROUTES } from '~/constants';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const OPTIONS: string[] = [
  'Planowy',
  'Na własne żądanie',
  'Dyscyplinarny',
  'Warunkowy',
  'Tymczasowy',
  'Ze skierowaniem do szpitala',
  'Na własne żądanie ze skierowaniem do szpitala',
];

interface Props {
  visit: VisitDTO;
  patient: PatientDTO;
}

export const ExitGenerateView = ({ visit, patient }: Props) => {
  const [type, setType] = useState<string | null>(null);
  const [date, setDate] = useState<moment.Moment>(moment());
  const history = useHistory();
  const backViewUrl = createPatientVisitRoute(
    ROUTES.dashboard.patient.visit.ROOT,
    patient.id,
    visit.id,
  );

  const handleSetDate = useCallback((date: moment.Moment) => {
    setDate(date);
  }, []);

  const {
    data: documentData,
    mutate: generateDocument,
    isLoading: isGenerating,
  } = useGenerateExcerptDocument(visit.id);

  const { mutate: confirmExit, isLoading: isConfirming } = useConfirmVisitExit(
    patient.id,
    visit.id,
    () => {
      history.push(backViewUrl);
    },
  );

  const handleSubmitGenerate = useCallback(() => {
    if (type) {
      generateDocument({
        endReason: type,
        endDate: moment(date).format('YYYY-MM-DD'),
      });
    }
  }, [date, generateDocument, type]);

  const handleAccept = useCallback(() => {
    if (type && documentData) {
      confirmExit({
        endReason: type,
        endDate: moment(date).format('YYYY-MM-DD'),
        fileName: documentData.filename,
        filePath: documentData.path,
      });
    }
  }, [type, documentData, confirmExit, date]);

  const renderFormToGenerateDocument = useCallback(
    () => (
      <>
        <Typography.Title level={3}>Generuj poglądowy wypis</Typography.Title>
        <Typography.Paragraph>
          W jakim trybie chcesz wypisać pacjenta?
        </Typography.Paragraph>
        <Space direction='vertical'>
          <Select<string>
            onSelect={(el: string) => setType(el)}
            placeholder='Wybierz tryb'
            style={{ width: 360 }}
          >
            {OPTIONS.map((el, idx) => (
              <Select.Option value={el} key={idx}>
                {el}
              </Select.Option>
            ))}
          </Select>
          <Form.Item
            label='Data zakończenia wizyty'
            required
            style={{ width: '100%' }}
          >
            <DatePicker
              allowClear={false}
              placeholder='Data'
              onChange={handleSetDate}
              value={date}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Button
            onClick={handleSubmitGenerate}
            disabled={!type}
            loading={isGenerating}
          >
            Generuj poglądowy wypis
          </Button>
        </Space>
      </>
    ),
    [handleSetDate, date, handleSubmitGenerate, type, isGenerating],
  );

  const renderSuccessForm = useCallback(() => {
    return (
      <>
        <Typography.Title level={3}>
          Wygenerowano poglądowy wypis
        </Typography.Title>
        <Space direction='vertical' size='large'>
          <Button
            icon={<DownloadOutlined />}
            href={documentData?.previewUrl}
            type='primary'
          >
            {documentData?.filename}
          </Button>
          <Typography.Paragraph style={{ maxWidth: 600 }}>
            <b>Ściągnij wypis i potwierdź, że zawiera prawidłowe dane.</b> W
            momencie kliknięcia <b>&quot;Akceptuje wypis&quot;</b> pacjent trafi
            do archiwum. Wszelkie dane będą nadal dostepne do wglądu (brak
            możliwości edycji), natomiast zakończy on aktualną wizytę w
            placówce.
          </Typography.Paragraph>
          <Space direction='vertical'>
            <Button onClick={() => history.push(backViewUrl)}>
              Chcę coś jeszcze zmienić
            </Button>
            <Button
              onClick={handleAccept}
              loading={isConfirming}
              type='primary'
              danger
            >
              Akceptuje wypis i wypisuje pacjenta przenosząc aktualny jego pobyt
              do archiwum
            </Button>
          </Space>
        </Space>
      </>
    );
  }, [documentData, backViewUrl, isConfirming, handleAccept, history]);

  return (
    <div style={{ padding: 16 }}>
      {documentData ? renderSuccessForm() : renderFormToGenerateDocument()}
    </div>
  );
};
